var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.titleName) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "通知标题：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请输入通知标题", maxlength: "16" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通知方式：", prop: "informType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择通知类型" },
                  model: {
                    value: _vm.formData.informType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "informType", $$v)
                    },
                    expression: "formData.informType",
                  },
                },
                _vm._l(_vm.typeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "新消息：" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "0" },
                  model: {
                    value: _vm.formData.degree,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "degree", $$v)
                    },
                    expression: "formData.degree",
                  },
                },
                [_vm._v("否")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.formData.degree,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "degree", $$v)
                    },
                    expression: "formData.degree",
                  },
                },
                [_vm._v("是")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通知内容：", prop: "richText" } },
            [
              _c("Tinymce", {
                key: _vm.formData.id,
                attrs: { width: "100%", id: _vm.formData.id, height: 350 },
                model: {
                  value: _vm.formData.richText,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "richText", $$v)
                  },
                  expression: "formData.richText",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveArticle()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }