<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="titleName"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="通知标题：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入通知标题" maxlength="16" style="width: 400px"></el-input>
      </el-form-item>
      <!-- <el-form-item label="生效日期：" required >
        <date-picker ref="dataPicker" placeholder="请选择生效日期范围" @updateView="datetTimeGet"></date-picker>
      </el-form-item> -->
      <el-form-item label="通知方式：" prop="informType">
        <el-select v-model="formData.informType" filterable placeholder="请选择通知类型">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="通知频率：" prop="frequency">
        <el-select v-model="formData.frequency" filterable placeholder="请选择通知频率">
          <el-option
            v-for="item in popupOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="新消息：">
        <el-radio v-model="formData.degree" label="0">否</el-radio>
        <el-radio v-model="formData.degree" label="1">是</el-radio>
      </el-form-item>
      <el-form-item label="通知内容：" prop="richText">
        <Tinymce v-model="formData.richText" width="100%" :id="formData.id" :key="formData.id" :height="350" />
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="saveArticle()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import {addNotify, editNotify } from '@/api/notify'
import datePicker from '@/components/date-picker'
import Tinymce from '@/components/newTinymce'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    notifyData: {
      default: () => {
        return {}
      },
      type: Object
    },
    titleName:{
      default: '',
      type: String
    }
  },
  // 监控表单变化，清除验证
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  components: {datePicker,Tinymce},
  data () {
    return {
      minStartTime:'',
      maxStartTime:'',
      formData: {},
      rules: {
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        informType: [{ required: true, message: '请选择通知类型', trigger: 'blur' }],
        frequency: [{ required: true, message: '请选择通知频率', trigger: 'blur' }],
        richText: [{ required: true, message: '请输入通知内容', trigger: 'blur' }],
      },
      popupOptions: [
        {
          value:'daily',
          label:'每日首次登录'
        },{
          value:'none',
          label:'每次登录'
        },{
          value:'once',
          label:'首次登录'
        }
      ],
      typeOptions: [
        {
          value:'静默',
          label:'静默'
        },{
          value:'弹窗',
          label:'弹窗'
        }
      ],
    }
  },
  methods: {
    datetTimeGet(data) {
      if(data === null) {
        this.minStartTime = ''
        this.maxStartTime =  ''
      }else {
        this.minStartTime = data[0]
        this.maxStartTime =  data[1]
      }
    },
    openDialog () {
      console.log(this.formData,'dous');
      if (this.titleName === '新增弹窗') {
        this.formData = {
          degree:'1',
          richText:'',
          id:'05561aca-e455-4999-9999-678965b0f795'
        }
        
        this.$nextTick(()=> {
          setTimeout(()=> {
            window.tinymce.get(this.formData.id).setContent('')
          },500)
        })
        this.minStartTime = ''
        this.maxStartTime =  ''
      } else {
        this.formData = Object.assign({}, this.notifyData)
        this.minStartTime = this.notifyData.expiredStart
        this.maxStartTime = this.notifyData.expiredEnd
        this.$nextTick(()=> {
          setTimeout(()=> {
            window.tinymce.get(this.formData.id).setContent(this.notifyData.richText || '')
          },500)
        })

      }
    },

    close () { 
      this.$emit('close');
      this.formData = {
        richText:'',
      }
    },

    saveArticle () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.notifyData === null) {
            addNotify({
              ...this.formData,
              frequency:"once",
              type:'图文',
              system:'merchant',
              expiredEnd:this.maxStartTime,
              expiredStart:this.minStartTime
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            editNotify({
              id:this.notifyData.id,
              ...this.formData,
              type:'图文',
              system:'merchant',
              expiredEnd:this.maxStartTime,
              expiredStart:this.minStartTime
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
