import $axios from '@/utils/request'

// 获取通知列表
export function listNoticeOnAdmin (data) {
  const url = '/pageBanner/listNoticeOnAdmin'
  return $axios.fPost(url, data)
}

// 新增通知
export function addNotify (data) {
  const url = '/pageBanner/add'
  return $axios.fPost(url, data)
}

// 编辑通知
export function editNotify (data) {
  const url = '/pageBanner/edit'
  return $axios.fPost(url, data)
}

// 删除通知
export function deleteNotify (data) {
  const url = '/pageBanner/delete'
  return $axios.fDelete(url, data)
}
