<template>
  <el-row class="table">
    <span class="title">通知管理</span>
    <el-form :inline="true" :model="queryData">
      <div class="row-bg" style="padding-top: 20px;margin-top: 15px;">
        <el-row :gutter="24">

          <el-col :md="4" :sm="12">
            <el-form-item label="">
              <el-select
                clearable
                v-model="queryData.seleState"
                placeholder="选择通知状态"
                class="w100"
              >
                <el-option
                  v-for="item in notifyState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="12">
            <el-form-item label="">
              <el-select
                clearable
                v-model="queryData.seleType"
                placeholder="选择通知类型"
                class="w100"
              >
                <el-option
                  v-for="item in notifyType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="12">
            <el-form-item label="">
              <el-select
                clearable
                v-model="queryData.seleDegree"
                placeholder="选择是否新消息"
                class="w100"
              >
                <el-option
                  v-for="item in notifyDegree"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="4" :sm="12">
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>通知数据</span>
        <div class="flex_1"></div>
        <el-button
          type="primary"
          size="small"
          @click="addNotify"
          style="margin-left: 20px"
        >新增通知</el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          :row-key="tableKey + ''"
          fit
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '通知内容'">
                <span
                  class="title-link"
                  @click="quesDetail(scope.row)"
                  style="line-height: 25px"
                >{{ scope.row.title }}</span
                >
              </template>
              <template v-else-if="item.label === '通知类型'">
                <el-tag :type=" scope.row.informType === '静默'?'warning':'success'">{{scope.row.informType}}</el-tag>
              </template>
              <template v-else-if="item.label === '是否新消息'">
                <el-tag :type=" scope.row.degree === '1'?'success':'info'">{{scope.row.degree === '1' ?'是':'否'}}</el-tag>
              </template>
              <template v-else-if="item.label === '状态'">
                <el-tag :type=" scope.row.status === '启用'?'success':'info'">{{scope.row.status}}</el-tag>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button

                  type="text"
                  @click.native="editNotify(scope.row)"
                >编辑
                </el-button>
                <el-dropdown style="margin-left: 10px">
                  <span class="el-dropdown-link" style="font-size: 14px;">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if=""
                      @click.native="editNotifyStatus(scope.row)"
                    >{{ scope.row.status === '启用' ?'撤销发布':'发布' }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      size="small"
                      type="primary"
                      plain
                      @click.native="deleteNotify(scope.row)"
                    >删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <add-notify :show="notifyDialog" :titleName='titleName' @close="notifyDialog=false,notifyData=null" :notifyData="notifyData" @updateView="getNotifyList"></add-notify>
  </el-row>
</template>

<script>
import TextButton from "@/components/TextButton";
import addNotify from '@/components/operation/addNotify'
import {
  listNoticeOnAdmin,
  editNotify,
  deleteNotify
} from "@/api/notify";
// 默认展示列
const baseColSetting = [
  {
    label: "通知标题",
    state: true,
    prop:'title',
    align: "center",
  },
  {
    label: "通知类型",
    state: true,
    prop: 'informType',
    align: "center",
  },
  {
    label: "是否新消息",
    state: true,
    align: "center",
  },
  {
    label: "状态",
    state: true,
    prop: 'status',
    align: "center",
  },
  {
    label: "创建时间",
    state: true,
    prop: 'createTime',
    align: "center",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: 100,
  },
];
export default {
  name: "notify-manage",
  components: {
    TextButton,
    addNotify
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  data() {
    return {
      titleName :'新增弹窗',
      notifyData:null,
      notifyDialog:false,
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      notifyState: [
        {
          label: "全部状态",
          value: "",
        },
        {
          label: "已发布",
          value: "启用",
        },
        {
          label: "未发布",
          value: "关闭",
        },
      ],
      notifyDegree: [
        {
          label: "全部消息",
          value: "",
        },
        {
          label: "新消息",
          value: "1",
        },
        {
          label: "普通消息",
          value: "0",
        },
      ],
      notifyType: [
        {
          label: "全部类型",
          value: "",
        },
        {
          label: "静默",
          value: "静默",
        },
        {
          label: "弹窗",
          value: "弹窗",
        },
      ],
      queryData: {
        seleState: "",
        seleType: '',
        seleDegree:'',
      },
      tableKey: 1,
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getNotifyList();
  },

  methods: {
    async editNotifyStatus(row) {
      const response = await editNotify({
        id:row.id,
        status:row.status === '启用' ? '关闭' : '启用'
      })
      if(response && response.state === 'success') {
        window.$msg('状态修改成功')
        this.getNotifyList()
      }
    },
    deleteNotify(row) {
      this.$confirm('确定删除当前通知吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await deleteNotify({
            id: row.id
          })
          window.$msg('删除成功')
          this.getNotifyList()
        })
        .catch(() => {
          return false
        })
    },
    addNotify() {
      this.notifyData = null
      this.notifyDialog = true
      this.titleName = '新增弹窗'
    },
    editNotify(row) {
      this.notifyData = row
      this.notifyDialog = true
      this.titleName  = '编辑弹窗'
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    getKey(row) {
      return row.problem_id;
    },

    // 获取问题列表
    async getNotifyList() {
      const res = await listNoticeOnAdmin({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status:this.queryData.seleState,
        informType:this.queryData.seleType,
        degree:this.queryData.seleDegree,
      });
      this.dataList = res.body.list
      // .sort(function(a, b) {
      //   a = new Date(a.createTime).getTime();
      //   b = new Date(b.createTime).getTime();
      //   return b-a;
      // });
      this.pagination.total = res.body.total;
    },

    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getNotifyList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getNotifyList();
    },

    // 搜索
    search() {
      if (this.queryData.select === "2") {
        let reg = /^[0-9]*$/;
        if (!reg.test(this.queryData.inputVal)) {
          window.$msg("请输入正确的id", 2);
          return false;
        }
      }
      this.pagination.currentPage = 1;
      this.getNotifyList();
    },

    // 重置按钮
    reset() {
      this.queryData = {
        inputVal: "",
        select: "1",
      };
    },

    // 携带问题的id标识，跳转题目详情页面
    quesDetail(row) {
      let routeData = this.$router.resolve({
        path: "/research/train-question-details",
        query: {
          problemID: row.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.el-card__header {
  padding: 10px 20px;
}

/deep/ .el-card__body {
  padding: 0px 20px;
}

/deep/ .el-table .cell.el-tooltip {
  white-space: normal;
}

.trDascader /deep/ .el-cascader__search-input {
  min-width: 20px !important;
}
</style>
